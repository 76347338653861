import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()
window.$ = jQuery

// 画像
require.context('img/', true)

import "bootstrap-material-design"
import "vendor/material-dashboard-pro-html-v2.2.2/assets/js/material-dashboard"
import "vendor/material-dashboard-pro-html-v2.2.2/assets/js/plugins/jquery.color.min"
import "bootstrap-notify"
import "vendor/fontawesome-pro-6.0.0-beta1/js/all.min"
import "bootstrap-select"
import "ajax-bootstrap-select"
import "data-confirm-modal"

import "jquery-ui/ui/widgets/draggable"

// 分析関連
import "chartkick/chart.js"
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { AjaxBootstrapSelectHandler } from './lib/ajax-bootstrap-select-handler';
import {bindItemSearchEvent} from "../js/lib/item-search-modal"
import {bindNoticeEvent} from "../js/lib/notice"
import {bindAutoLogoutEvent} from "../js/lib/auto-logout"
import {bindSideMenuEvent, showUpperMenu} from "../js/lib/side-menu"
import {bindSupplierUserEmailEvent} from "./supplier_users/email"
import {renderSidebarNotification} from "./lib/sidebar-notification"

// 後で個別ファイルに切り出したい
function bindAjaxFormEvent(formId, scope, onSuccess, onError) {
  $(`form#${formId}`).on("ajax:success", function(event) {
    // 成功時の処理
    const [data, status, xhr]  = event.detail;

    // アラートの追加
    // 既存のアラートを消す
    $(this).find(".alert").remove();
    // アラート用div作成
    let $alertDiv = $("<div class='alert alert-success'>");
    $alertDiv.append($("<div>").text(data.message));
    $(this).prepend($alertDiv);

    // has-dangerを全部はずす
    $(this).find(".bmd-form-group.has-danger").removeClass("has-danger");

    // 独自コールバック実行
    onSuccess(this, event);
  }).on("ajax:error", function(event) {
    // エラー時の処理
    const [data, status, xhr]  = event.detail;

    // アラートの追加
    // 既存のアラートを消す
    $(this).find(".alert").remove();
    // アラート用div作成
    let $alertDiv = $("<div class='alert alert-warning'>");
    let $messagesUl = $("<ul class='mb-0'>");
    data.errors.full_messages.forEach((message) => {
      $messagesUl.append($("<li>").text(message));
    });
    $alertDiv.append($messagesUl);
    $(this).prepend($alertDiv);

    // バリデーションエラー項目にhas-dangerをつける
    // has-dangerを全部はずす
    $(this).find(".bmd-form-group.has-danger").removeClass("has-danger");
    // 必要な分has-dangerをつける
    data.errors.attribute_names.forEach((name) => {
      $(this).find(`#${scope}_${name}`).closest(".bmd-form-group").addClass("has-danger");
    });

    // 独自コールバック実行
    onError(this, event);
  });
  // フォームを開いたときに前回の入力情報が残らないようにリセットするイベントを付与する
  $("a[data-target='#passwordEditModal']").on("click", function() {
    $('#edit_user_password div.alert').remove();
    $('#edit_user_password input[type=password]').val('');
  });
};

function clearForm(form) {
    $(form).find("input[name!='_method'][name!='authenticity_token']").val("");
    $(form).find("textarea").val("");
    $(form).find("select option").prop("selected", false);
};

function setupTempDeptModal() {
  $.fn.AjaxBootstrapSelectHandler = AjaxBootstrapSelectHandler;
  // Mobile版でクリックイベントが発火しないため，onclickで直指定させている
  /*
  $('#change-dept-temp').click(function(event) {
    $.ajax({
      'url' : '/users/temporal_departments/new',
      'dataType' : 'script',
      'data' : { '_method' : 'GET' }
    });
  });
  */
};

// TODO: 全部のモーダルに対して正常に動作するか保証するのが難しい...
// 少なくとも.modalの中に.modalがあるとモーダルが表示されなくなる挙動は確認した
//    詳しくはこの行がコミットされたのと同じコミットのapp/views/top/_schedule_modal.html.erbのdiffを見てほしい
// handleに指定されたDOM内のbs.popoverも無効になっていた
var setupDraggable = function() {
  // modalが表示されるタイミングでdraggableにして、消えたタイミングでdraggableを止める
  // .card-headerを動的に設定する場合があるため。
  // このコールバック内でモーダルの位置を初期化するロジックがあるので、モーダルが表示される直前であるshow.bs.modalイベントに貼り付ける
  // shown.bs.modalだと表示されるアニメーションの最中にモーダルの位置が変わる挙動をしてしまい不自然
  $('body').on("show.bs.modal", '.modal', function(evt) { // NOTE: bodyを対象にすれば動的に追加されたものも含めて大体のモーダルは対象になるはず...
    var $modal_div = $(evt.target);

    // confirmモーダルにdraggableを適用する必要はないのでreturnする
    if ($modal_div.attr('id').match(/^confirm-modal/)) { // NOTE: "confirm-modal"から始まるidがconfirm-modalのはず...
      return;
    }

    // 移動対象のDOM
    var $modal_dialog_div = $modal_div.find(".modal-dialog");

    // top: 0・left: 0の時にwindowの左上に来るようにclassを調整する
    // draggableの<containment: "window">オプションを利用したいため
    $modal_dialog_div.removeClass("modal-dialog-centered");
    $modal_dialog_div.addClass("m-0");

    // show.bs.modalイベントに貼り付けているため
    // 現時点ではモーダルは表示されておらず、モーダルの高さ・幅が取得できない
    // そのため一時的にdisplay: noneを解除してvisiblityをhiddenにする
    $modal_div.css({
      'display': 'block',
      'visiblity': 'hidden',
    });
    // 再度モーダルを表示した時に、位置が中央になるように計算
    // windowの大きさとモーダルの大きさで決定する
    var left = Math.floor(($(window).width() - $modal_dialog_div.width()) / 2);
    var top  = Math.floor(($(window).height() - $modal_dialog_div.height()) / 2);
    $modal_dialog_div.css({ "top": Math.max(0, top), "left": left, });
    // displayとvisiblityを戻す
    $modal_div.css({
      'display': 'none',
      'visiblity': '',
    });

    // これとdraggableのstartとstopを合わせて
    // hoverした時と移動中の時にカーソルを制御
    // cursorオプションは移動中だけしか制御できない(modal-headerにhoverした時に移動できる感が出ない)ので
    // ここの初期化とstart/stopでstyleのcursorを制御する
    var handleExp = '.modal-header'; // 商品詳細モーダルにはcard-headerがないのでmodal-headerにする(全モーダルにmodal-headerはあるはず)
    var $handle = $modal_div.find(handleExp);
    $handle.css({
      'cursor': 'grab'
    });

    $modal_dialog_div.draggable({
      handle: handleExp,
      scroll: false,
      containment: "window", // 画面の外に出ないようにする(draggableの設定)
      drag: function(event, ui) {
        // containment: "window"を指定すると
        // windowの高さがモーダルの高さより小さい時に、モーダルを動かそうとするとモーダル上部が画面の外に行ってしまい
        // モーダル上部が表示されなくなってしまうので、どんな場合でも画面上部を超えないように制御
        if (ui && ui.position && ui.position.top < 0) {
          ui.position.top = 0;
        }
      },
      start: function(event, ui) {
        $handle.css({
          'cursor': 'grabbing'
        });
      },
      stop: function(event, ui) {
        $handle.css({
          'cursor': 'grab'
        });
      },
    });

    // hide.bs.modalイベントには、閉じる確認ダイアログが出現するイベントリスナがあるので
    // hidden.bs.modalイベントでdestroyをしないとバグる
    $modal_div.one("hidden.bs.modal", function() {
      $modal_dialog_div.draggable("destroy");
    });
  });
};

// 添付ファイルのバリデーション処理
function setupValidateAttachments(){
  // confirm画面でもattachment-wrapperが存在するため、
  // input[type=file]が一つでも存在するとき、validationのセットアップをする
  if($('input[type=file]').length > 0){
    let dialog_flag = false;
    const filterDropEventHandler = function(evt){
      let validate = true;
      // inputを押したときダイアログが表示される。そのときドラッグでの添付をできないようにする
      // ダイアログが表示されているかどうかはdialog_flagで管理する
      if (validate && dialog_flag){
        if ($('div.alert-warning[data-notify="container"]').length == 0) {
          $.notify('ファイル選択中はドラッグでファイルを添付することはできません', { type: 'warning', z_index: 1051 });
        }
        validate = false;
      }

      // windows上のアプリケーションからファイルをドラッグアンドドロップしたとき
      // itemsの中にファイルの情報以外に文字列の情報が入ってくるため、kindがファイルのものを取得する
      // ファイルを2つ以上添付したときは個々のattachment.jsではじくようになっているため、
      // 最初のファイルだけ添付していいかどうか確認する
      let items = evt.originalEvent.dataTransfer.items;
      let fileItem = null;

      for (let i = 0; i < items.length; i++) {
        if (items[i].kind === 'file') {
          fileItem = items[i];
          break;
        }
      } 
      
      // 中身のないファイルを添付できないようにする
      if (validate && !(fileItem && evt.originalEvent.dataTransfer.files[0])){
        if ($('div.alert-warning[data-notify="container"]').length == 0) {
          $.notify("このファイルは添付できません", { type: 'warning', z_index: 1051 });
        }
        validate = false;
      }

      // ディレクトリーは添付できないようにする
      if (validate && fileItem.webkitGetAsEntry()?.isDirectory){
        if ($('div.alert-warning[data-notify="container"]').length == 0) {
          $.notify("フォルダを添付することはできません", { type: 'warning', z_index: 1051 });
        }
        validate = false;
      }

      // 拡張子によって添付できないようにする
      let acceptedFileType = ['lnk'];
      if (validate && acceptedFileType.includes(evt.originalEvent.dataTransfer.files[0].name.split('.').pop())){
        if ($('div.alert-warning[data-notify="container"]').length == 0) {
          $.notify("ショートカットファイルを添付することはできません", { type: 'warning', z_index: 1051 });
        }
        validate = false;
      }

      if (!validate){
        $(this).closest('#attachment-wrapper').css({ border : "2px dashed #dbdbdb" }); // dragenterで太くなった枠を戻す
        evt.preventDefault();
        evt.stopPropagation();
      }

      // dropしたときdragleaveが呼ばれないため、counterが減らない
      // そのためdragleaveイベントを発火させる
      $('#attachment-wrapper').trigger('dragleave');
    }
    
    $('#attachment-wrapper').on('click', function(){
      dialog_flag = true;
    });

    $('#attachment-wrapper').on('input cancel', function(){
      dialog_flag = false;
      $('#attachment-wrapper .filter-dropzone-area').remove();
      $(this).closest('#attachment-wrapper').css({ border : "2px dashed #dbdbdb" }); // dragenterで太くなった枠を戻す
    });

    let counter = 0;
    $('#attachment-wrapper').on('dragenter', function(evt) {
      counter++;
      if ($('.filter-dropzone-area').length == 0){
        // ファイルがドラッグされたとき、validation処理をするために、添付ファイルのフィールドを覆うように要素を追加する
        $('#attachment-wrapper').append($("<div class='filter-dropzone-area justify-content-center position-absolute' style='align-items: center; width: calc(100% + 48px); height: calc(100% + 48px); top: -24px; right: -24px;'>"));
        $('.filter-dropzone-area').on('drop', filterDropEventHandler);
      }
    });

    $('#attachment-wrapper').on('dragleave', function(evt) {
      counter--;
      if (counter <= 0) {
        // 基本負の数にはならないと思うが、なってしまったときように0に戻すようにする
        counter = 0;
        $('#attachment-wrapper .filter-dropzone-area').remove();
      }
    });
  }
}

$(function() {
  setupDraggable();

  // ユーザーのパスワード変更フォーム
  bindAjaxFormEvent("edit_user_password", "user_password", clearForm, clearForm);
  bindItemSearchEvent();
  bindNoticeEvent();
  bindSideMenuEvent();
  bindSupplierUserEmailEvent();
  showUpperMenu();
  // 部署変更modal
  setupTempDeptModal();
  renderSidebarNotification($('.sidebar-notification'));

  // chartkickが読み込んだChartにpluginを登録する
  Chartkick.adapters[0].library.register(ChartDataLabels);

  // ログイン画面以外で、自動ログアウト発動させる
  if (["/login", "/receipts/login/", "/receipts/receipts"].includes(window.location.pathname)) {
    bindAutoLogoutEvent();
  }

  // モーダルを多重で開いた場合に、閉じたら下のモーダルがスクロールできなくなる問題の対応
  $(document).on('hidden.bs.modal', '.modal', function () {
    $('.modal:visible').length && $(document.body).addClass('modal-open');
  });

  setupValidateAttachments();
});
